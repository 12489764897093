
import React from "react";
import "./App.css";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'


class Home extends React.Component {
  render() {
    return (
      <div>
        <div class="container">
          <div class="row">
            <div class="col-4">
              <br></br>
              <img className="coloListProfile" src="h1_new22.jpeg" alt="c1"></img>
              <img className="coloList" src="h3.jpg" alt="c3"></img>
              <img className="coloList" src="h4.JPG" alt="c4"></img>
              <br></br>
              <br></br>
            </div>
            <div class="col-7">
              <div className="bodyText">
                <br></br>
                <div className="titleText">
                  Välkommen till Barbro Källén Fysioterapi!
                </div>

                <br></br>

                <div className="bodyText">
                Hos mig kan du som har led-, muskel eller nervrelaterade besvär få hjälp med värk eller skador. 
                Jag arbetar även med stressrelaterade symptom som huvudvärk, somatisk tinnitus eller yrsel. 
                I huvudsak arbetar jag med ortopedisk manuell terapi (OMT) men är även utbildad i bl.a. McKenzie, akupunktur och idrottsmedicin. 
                </div>

                <br></br>

                <div className="bodyText">
                  Jag erbjuder även ergonomisk konsultation och utför utbildningar i ergonomi på arbetsplatser. 
                </div>

                <br></br>

                <div className="bodyText">
                  Min klinik finns på Gullbergs Strandgata 6 nära Holmens i Göteborg. 
                  Det går bra att boka tid via nätet eller söka mig på telefon.
                </div>

                <br></br>
                <br></br>




                <div class="aktuellt"> 
                  <div className="titleTextInfo">
                    AKTUELLT
                  </div>

                  <div className="bodyTextInfo">
                  Vi uppmanar våra besökare att fortsätta vara noga med att hålla avstånd, 
                  ha noggrann handhygien och att inte besöka oss vid symtom på förkylning.
                  </div>

                </div>


                <br></br>













                <br></br>
               



                




















              </div>
            </div>
          </div>
        </div>

        <hr class="bo"></hr>
        
        <div className="infoTextAll">
        <div class="infoTextBold">Barbro Källén Fysioterapi AB </div>
          Gullbergs Strandgata 6, 411 04 Göteborg

          <br></br>
          <br></br>

          <div class="info-group">
            <div class="infoTextBold">Telefon </div>
            <div class="infoText">: 0721 - 88 53 88</div>
          </div>

          <div class="info-group">
            <div class="infoTextBold">E-post </div>
            <div class="infoText">: barbro@kallenfysio.se</div>
          </div>

          <br></br>
          <div>Jag har avtal med Västra Götalandsregionen. Ett besök kostar 200:- och ingår i högkostnadsskyddet. Frikort gäller.</div>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>

      </div>
    );
  }
}

export default Home; 