
import React, { Component } from "react";
import "./App.css";



class Contact extends React.Component {
  render() {
    return ( 
      <div>
        <div class="container">
          <div class="row">
            <div class="col-4">
              <br></br>
              <img className="coloList" src="k4.jpg" alt="c4"></img>
              <img className="coloList" src="k1.JPG" alt="c1"></img>
              <img className="coloList" src="k2.JPG" alt="c2"></img>
              <img className="coloList" src="k3.JPG" alt="c3"></img>
              <br></br>
              <br></br>
            </div>
            <div class="col-7">
              <br></br>
              <div className="titleText">
                Kontakt
              </div>

              <br></br>

              <div className="bodyText">

              <div className="bodyTextBold">Hitta hit</div>
                
                <div className="bodyText">
                  Min klinik finns i Fungeras lokaler på Gullbergs Strandgata 6 i Göteborg. 
                  Det finns parkering bakom huset utefter älven eller under Götaälvbron. 
                  Åker du buss går du lättast från Operans hållplats eller Nordstan.
                </div>
 
                <br></br>

                <div className="bodyTextBold">Boka tid</div>

                <div className="bodyText">
                Du hittar hur du bokar tid under rubriken Boka tid. Länken som finns där kan användas till att boka tid för både nybesök eller återbesök. 
                </div>

                <br></br>

                <div className="bodyTextBold">Telefon</div>

                <div className="bodyText">
                  Det går även bra att ringa på 0721 - 88 53 88. 
                </div>

                <br></br>

                <div className="bodyTextBold">Återbud</div>

                <div className="bodyText">
                  Återbud till bokad tid lämnas på telefon senast klockan 8.00 samma dag.
                </div>

                <br></br>
                <br></br>
                
                
            
              </div>
            </div>
          </div>
        </div>

        <hr class="bo"></hr>

        <div className="infoTextAll">
        <div class="infoTextBold">Barbro Källén Fysioterapi AB </div>
          Gullbergs Strandgata 6, 411 04 Göteborg

          <br></br>
          <br></br>

          <div class="info-group">
            <div class="infoTextBold">Telefon </div>
            <div class="infoText">: 0721 - 88 53 88</div>
          </div>

          <div class="info-group">
            <div class="infoTextBold">E-post </div>
            <div class="infoText">: barbro@kallenfysio.se</div>
          </div>

          <br></br>
          <div>Jag har avtal med Västra Götalandsregionen. Ett besök kostar 200:- och ingår i högkostnadsskyddet. Frikort gäller.</div>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>


      </div> 
    );
  }
}

export default Contact; 