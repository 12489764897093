
import React, { Component } from "react";
import "./App.css";

class BookTime extends React.Component {
//<meta name="viewport" content="width=device-width, initial-scale=1.0">


btnBook(){
  window.open("https://patient.nu/portal/calendar/0e6210e4-4135-11eb-8761-954289e7eae2");
}



  render() {
    return (
      <div> 

        <div class="container">
          <div class="row">
            <div class="col-4">
              <br></br>
              <img className="coloList" src="b1.JPG" alt="c1"></img>
              <img className="coloList" src="b2.JPG" alt="c2"></img>
              <img className="coloList" src="b3.JPG" alt="c3"></img>
              <img className="coloList" src="b4.JPG" alt="c4"></img>
              <br></br>
              <br></br>
            </div>
            <div class="col-7">
              <br></br>
              <div className="titleText">
                Boka tid
              </div>
 
              <br></br>

              <div className="bodyText">
                  Tid för nybesök eller återbesök bokas via nedanstående länk.  
                </div>


              <br></br>

              <div className="bodyText">
                <button type="button" className="bookButton" onClick={this.btnBook.bind(this)}>
                  TRYCK HÄR FÖR ATT BOKA TID
                </button>
              </div>

              <br></br>

              <br></br>

            

              <div className="bodyTextBold">Telefon</div>

              <div className="bodyText">
                  Det går även bra att ringa på 0721 - 88 53 88. 
                </div>

              <br></br>

              <div className="bodyTextBold">Pris</div>

              <div className="bodyText">
                Ett besök kostar 200:- och ingår i högkostnadsskyddet. Frikort gäller. Under 20 och över 85 år betalar ingen patientavgift. 
              </div>

              <br></br>

              



            </div>

          </div>
        </div>

        <hr class="bo"></hr>

        <div className="infoTextAll">
        <div class="infoTextBold">Barbro Källén Fysioterapi AB </div>
          Gullbergs Strandgata 6, 411 04 Göteborg

          <br></br>
          <br></br>

          <div class="info-group">
            <div class="infoTextBold">Telefon </div>
            <div class="infoText">: 0721 - 88 53 88</div>
          </div>

          <div class="info-group">
            <div class="infoTextBold">E-post </div>
            <div class="infoText">: barbro@kallenfysio.se</div>
          </div>

          <br></br>
          <div>Jag har avtal med Västra Götalandsregionen. Ett besök kostar 200:- och ingår i högkostnadsskyddet. Frikort gäller.</div>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>

      </div>
    );
  }
}

export default BookTime; 