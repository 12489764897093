import React, { Component } from "react";
import ReactDOM from "react-dom";

import "./App.css";
import Home from "./Home";
import BookTime from "./BookTime";
import Competence from "./Competence";
import Contact from "./Contact";

//import ReactDOM from "react-dom"; 
//import "bootstrap/dist/css/bootstrap.css";
//import "bootstrap/dist/js/bootstrap.js";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
// import $ from "jquery"; // skip this if you do not use bootstrap modals
// import Popper from "popper.js"; // skip this if you do not use bootstrap modals

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";


class App extends Component { 
  constructor() {
    super();
  }

  render() {

    return (
      <Router>
        <Switch>
        <div className="main">
          <div className="header">
            
          <img className="topLogo" src="logga.jpg" alt="ab"></img>

              <ul>
                <nav className="nav" class="navbar navbar-inverse">

                  <a className="link">
                    <Link className="to-link" to="/">
                      HEM
                    </Link>
                  </a>

                  <a className="link">
                    <Link className="to-link" to="/Competence">
                      KOMPETENS OCH BEHANDLINGAR
                    </Link>
                  </a>

                  <a className="link">
                    <Link className="to-link" to="/BookTime">
                      BOKA TID
                    </Link>
                  </a>

                  <a className="link">
                    <Link className="to-link" to="/Contact">
                      KONTAKT
                    </Link>
                  </a>
                </nav> 
              </ul> 

              <Route exact path="/" component={Home} />
              <Route path="/BookTime" component={BookTime} />
              <Route path="/Competence" component={Competence} />
              <Route path="/Contact" component={Contact} />

          </div>




          
        </div>
        </Switch>
      </Router>
    );
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

export default App;


 