
import React from "react";
import "./App.css";


class Competence extends React.Component {
  render() {
    return (
      <div>
        <div class="container">
          <div class="row">
            <div class="col-4">
              <br></br>
              <img className="coloList" src="kb1.jpg" alt="c1"></img>
              <img className="coloList" src="kb2.JPG" alt="c2"></img>
              <img className="coloList" src="kb3.JPG" alt="c3"></img>
              <img className="coloList" src="kb4.JPG" alt="c4"></img>
              <br></br>
              <br></br>
            </div>
            <div class="col-7">
              <br></br>
              <div className="titleText">
                Kompetens och behandlingar
              </div>

              <br></br>

              <div className="bodyTextBold">Ortopedisk manuell terapi (OMT)</div>

              <div className="bodyText">
              Jag är utbildad specialist inom ortopedisk manuell terapi (OMT). 
              OMT är ett internationellt koncept som innefattar en grundlig anamnes, 
              undersökning och bedömning/åtgärdsplan i samråd med patienten. 
              För mer information om OMT, <a class="fungera" href="https://www.fysioterapeuterna.se/Om-forbundet/Sektioner/OMT/Vad-ar-OMT/">klicka här</a>. 
              </div>

              <br></br>


              <div className="bodyTextBold">Ergonomi</div>

              <div className="bodyText">
              Som utbildad företagsfysioterapeut arbetar jag även med ergonomi, både genom 
              individuell rådgivning och genom ergonomisk genomgång på arbetsplats, samt 
              föreläsningar i belastningsergonomi i personalgruppen.
              </div>

              <br></br>

              <div className="bodyTextBold">Övrig kompetens</div>

              <div className="bodyText">
              Utöver det är jag utbildad inom nervdynamik, idrottsmedicin, motorisk kontroll, yrselproblematik, 
              akupunktur och stressrelaterade tillstånd. Jag har även specialintresse för 
              somatisk tinnitus och har samarbetat med öron-näsa-hals-kliniker i 20 år när det gäller 
              tinnituspatienter.
              </div>

              <br></br>

                <div className="bodyTextBold">Fungera</div>

              <div className="bodyText">
              Ett gott samarbete med Fungera Rehabklinik gör att vi har bred kompetens i lokalen. 
              Vi har även tillgång till stora utrymmen med gruppverksamhets- och träningssalar. 
              För mer information om Fungera Rehabklinik, <a class="fungera" href="https://www.fungera.info">klicka här</a>. 
              </div>

              <br></br>
              
            </div>
          </div>
        </div>

        <hr class="bo"></hr>

        

        <div className="infoTextAll">
        <div class="infoTextBold">Barbro Källén Fysioterapi AB </div>
          Gullbergs Strandgata 6, 411 04 Göteborg

          <br></br>
          <br></br>

          <div class="info-group">
            <div class="infoTextBold">Telefon </div>
            <div class="infoText">: 0721 - 88 53 88</div>
          </div>

          <div class="info-group">
            <div class="infoTextBold">E-post </div>
            <div class="infoText">: barbro@kallenfysio.se</div>
          </div>

          <br></br>
          <div>Jag har avtal med Västra Götalandsregionen. Ett besök kostar 200:- och ingår i högkostnadsskyddet. Frikort gäller.</div>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>

        


      </div>
    );
  }
}

export default Competence; 